import React from 'react';
import { Avatar, Grid, IconButton, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  WhatsApp,
  Facebook,
  Instagram,
} from '@mui/icons-material';
import logoSeba from '../../utils/img/desarrolladores/Logotipo_Personal(Sebastian_Legnazzi).png'
import logoDari from '../../utils/img/desarrolladores/Logotipo_Personal(dariana_sosa).png'
//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
  txt: {
    fontSize: '0.9rem',
    color: '#b5b5b5',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  iconFacebook: {
    backgroundColor: '#3b5998',
    width: '50px',
    height: '50px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  iconInstagram: {
    backgroundColor: '#e4405f',
    width: '50px',
    height: '50px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  iconWpp: {
    backgroundColor: '#25d366',
    width: '50px',
    height: '50px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  txtHeaderDevelop: {
    fontSize: '1.2rem',
    color: '#b5b5b5',
  },
  txtDevelop: {
    fontSize: '1rem',
    color: '#b5b5b5',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: '#FFC800',
    }
  },
  logo: {
    width: '120px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  containerRed: {
    [theme.breakpoints.down('sm')]: {
      paddingBlock: '15px',
      borderBottom: '1px solid #cfcfcf',
    },
  },
}));

const FooterV = () => {

  //======= VARIABLE ESTILOS =======
  const classes = useStyles();


  //======= Funcion que lleva a ver la direccion a WhatsAPP =======
  const openWpp = () => {
    const whatsApp = `https://api.whatsapp.com/send?phone=5492995319130&text=Hola!%20quisiera%20consultar%20por%20los%20cursos`;
    window.open(whatsApp, '_blank');
  };

  //======= Funcion que lleva a ver la direccion a Facebook =======
  const openFace = () => {
    const facebook = `https://www.facebook.com/AcademiaManejar`;
    window.open(facebook, '_blank');
  };

  //======= Funcion que lleva a ver la direccion a Instagram =======
  const openInsta = () => {
    const instagram = `https://www.instagram.com/academiamanejar/`;
    window.open(instagram, '_blank');
  };

  //======= Funcion que lleva a ver la direccion a Sebastian =======
  const openSeba = () => {
    const seba = `https://www.linkedin.com/in/sebastian-legnazzi/`;
    window.open(seba, '_blank');
  };

  //======= Funcion que lleva a ver la direccion a Dariana =======
  const openDari = () => {
    const dari = `https://www.linkedin.com/in/dariana-sosa-3408ba200/`;
    window.open(dari, '_blank');
  };


  return (
    <Grid container spacing={2}>
      <Grid item container alignContent="center" justifyContent="center" md={4}>
        <Typography className={classes.txt}>
          Copyright © Academia Manejar
        </Typography>
      </Grid>
      <Grid item container alignContent="center" justifyContent="center" spacing={3} md={4} >
        <Grid item>
          <IconButton
            onClick={openWpp}
          >
            <Avatar className={classes.iconWpp}>
              <WhatsApp />
            </Avatar>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={openFace}
          >
            <Avatar className={classes.iconFacebook}>
              <Facebook />
            </Avatar>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={openInsta}
          >
            <Avatar className={classes.iconInstagram}>
              <Instagram />
            </Avatar>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" md={4}>
        <Grid item>
          <Typography className={classes.txtHeaderDevelop}>
            Desarrolladores
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" align="center" spacing={3}>
          <Grid item>
            <IconButton
              onClick={openSeba}
            >
              <img src={logoSeba} alt="Logo Sebastian Legnazzi" className={classes.logo} />
            </IconButton>
            <Typography className={classes.txtDevelop}>
              <Link onClick={openSeba} underline="none" color="inherit">
                Sebastian Legnazzi
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={openDari}
            >
              <img src={logoDari} alt="Logo Sebastian Legnazzi" className={classes.logo} />
            </IconButton>
            <Typography className={classes.txtDevelop}>
              <Link onClick={openDari} underline="none" color="inherit">
                Dariana Sosa
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FooterV
