import React, {useContext} from 'react';
import {
    FilledInput,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    FormControl,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VpnKey } from '@mui/icons-material';
import { UserContext } from '../../../Providers/UserContext';

//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
    },
    container: {
       marginBottom: '20px', 
    },
    icon: {
        fontSize: '80px',
    },
    btnReg: {
        color: theme.palette.btn.main,
    },
    helpForm: {
        color: 'red',
    }
}));

const FormUserV = () => {

    //======= PROVIDER =======
    const { username, setUsername, password, setPassword, passwordRepeat, setPasswordRepeat } = useContext(UserContext);

    //======= VARIABLE ESTILOS =======
    const classes = useStyles();

    //======= VISTA =======
    return (
        <Grid item container md={10} alignContent="center" direction="column" spacing={1} className={classes.container}>
            <Grid item>
                <VpnKey className={classes.icon} />
            </Grid>
            <Grid item container spacing={2} className={classes.input} justifyContent="center">
            <Grid item md={8}>
                    <TextField
                        id="username"
                        color="letters"
                        label="Nombre de Usuario"
                        variant="filled"
                        value={username}
                        fullWidth
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        inputProps ={{ maxLength: 25, type:'text' }}
                    />
                </Grid>
                <Grid item md={8}>
                    <FormControl style={{ width: '100%' }} variant="filled">
                        <InputLabel htmlFor="outlined-adornment-password" color='letters'>Contraseña</InputLabel>
                        <FilledInput
                            id="contrasena"
                            type="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            color="letters"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                        <FormHelperText className={classes.helpForm}>  </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item md={8}>
                    <FormControl style={{ width: '100%' }} variant="filled">
                        <InputLabel htmlFor="outlined-adornment-password" color='letters'>Contraseña Repetida</InputLabel>
                        <FilledInput
                            id="contrasena repetida"
                            type="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                    >
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña Repetida"
                            color="letters"
                            value={passwordRepeat}
                            onChange={(e) => {
                                setPasswordRepeat(e.target.value);
                            }}
                        />
                        <FormHelperText className={classes.helpForm}>  </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormUserV