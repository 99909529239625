import React, { useContext } from 'react';
import {
    Grid,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VpnKey } from '@mui/icons-material';
import { UserContext } from '../../../Providers/UserContext';

//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
    },
    inputContainer: {
        backgroundColor: '#ffffff',
        borderRadius: '20px',
        boxShadow: '0px 2px 20px 3px rgba(0,0,0,0.75)',
        padding: '30px 0 30px 0',
        width: '90%',
    },
    icon: {
        fontSize: '80px',
    },
    btnReg: {
        color: theme.palette.btn.main,
    },
    helpForm: {
        color: 'red',
    },
    container: {
        marginBottom: '20px',
    },
}));

const FormPersonalV = () => {

    //======= PROVIDER =======
    const { name, setName, lastname, setLastname, email, setEmail, error } = useContext(UserContext);

    //======= VARIABLE ESTILOS =======
    const classes = useStyles();

    //======= VISTA =======
    return (
        <Grid item container md={10} alignContent="center" direction="column" spacing={1} className={classes.container}>
            <Grid item>
                <VpnKey className={classes.icon} />
            </Grid>
            <Grid item container spacing={2} className={classes.input} justifyContent="center">
                <Grid item md={8}>
                    <TextField
                        
                        id="nombre"
                        color="letters"
                        label="Nombre"
                        variant="filled"
                        value={name}
                        fullWidth
                        error={error}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        inputProps ={{ maxLength: 30, type:'text' }}
                    />

                </Grid>
                <Grid item md={8}>
                    <TextField
                        id="apellido"
                        color="letters"
                        label="Apellido"
                        variant="filled"
                        fullWidth
                        value={lastname}
                        error={error}
                        onChange={(e) => {
                            setLastname(e.target.value);
                        }}
                        inputProps ={{ maxLength: 30, type:'text' }}
                    />
                </Grid>
                <Grid item md={8}>
                    <TextField
                        id="email"
                        color="letters"
                        label="Email"
                        variant="filled"
                        type="email"
                        fullWidth
                        value={email}
                        error={error}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        inputProps ={{ maxLength: 200, type:'email' }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormPersonalV