import React, { createContext, useEffect, useState } from 'react';
import HomeC from '../general/Home/Home_C/HomeC';

// Crear un contexto
const HomeContext = createContext();

const controllerHome = new HomeC();

// Crear el componente Provider
const HomeProvider = ({ children }) => {

    // Aquí puedes definir el estado o los datos que deseas proporcionar en el contexto.
    const [carrousel, setCarrousel] = useState(null);
    const [us, setUs] = useState(null);
    const [courses, setCourses] = useState(null);
    const [urlInstructive, setUrlInstructive] = useState(null);
    const [video, setVideo] = useState(null);
    const [alert, setAlert] = useState({ mensagge: '', type: 'error', status: false });
    const [openModal, setOpenModal] = useState({ mensagge: '', title: '', subTitle: '', open: false, loading: false });

    //Funcion que cierra el alert
    const handleCloseAlert = () => {
        setAlert({ mensagge: '', type: alert.type, status: false });
    }

    useEffect(() => {
        //Busca todos los datos para el home
        if (!courses && !carrousel && !us && !urlInstructive) {
            controllerHome.getHome()
                .then((data) => {
                    if (data.ok) {
                        setCourses(data.data.arrayCurse);
                        setCarrousel(data.data.imgCarrosuel);
                        setUs(data.data.imgUs);
                        setUrlInstructive(data.data.urlInstructive.urlInstruct);
                        setVideo(data.data.arrayVideo);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    return (
        <HomeContext.Provider value={{
            us,
            setUs,
            carrousel,
            setCarrousel,
            courses,
            setCourses,
            setUrlInstructive,
            urlInstructive,
            handleCloseAlert,
            alert,
            setAlert,
            openModal,
            setOpenModal,
            video,
            setVideo,
        }}>
            {children}
        </HomeContext.Provider>
    );
};

export { HomeContext, HomeProvider };